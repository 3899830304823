<template>
  <v-row class="profile">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/users" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon> User profile
          </router-link>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
        <div class="d-flex flex-wrap justify-space-between mb-sm-0 mb-3">
          <div class="text-uppercase text-h5 font-weight-bold">General</div>
          <div class="status white--text" :class="getColorClass(data.status.name)">
            {{ data.status.title }} {{ data.deactivated_at ? new Date(data.deactivated_at).toLocaleDateString() : '' }}
          </div>
        </div>
        <div class="px-sm-10 py-sm-5">
          <v-row>
            <v-col cols="12" md="4">
              <div v-if="data.photo === null">
                <v-img width="256" src="@/assets/img/ava.svg" class="mx-md-0 mx-auto"></v-img>
              </div>
              <div v-else class="mx-auto">
                <v-img width="256" height="216" cover :src="data.photo"></v-img>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <div class="font-weight-medium">Full name</div>
              <div class="opasity--text">{{ data.first_name }} {{ data.last_name }}</div>
              <div class="font-weight-medium mt-3">Email</div>
              <div class="opasity--text">{{ data.email }}</div>
              <div v-if="data.is_email_confirmed" class="success--text f14">
                Confirmed {{ new Date(data.email_confirmed_at).toLocaleString() }}
              </div>
              <div v-else class="error--text f14">Email is not confirmed yet.</div>
              <div class="d-flex mt-3">
                <div class="font-weight-medium">Two factor authentication</div>
                <div class="ml-5 white--text f14 px-4 auth" :class="data['2fa_enabled'] ? 'success' : 'error'">
                  {{ data['2fa_enabled'] ? 'On' : 'Off' }}
                </div>
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-10"></v-divider>
          <div class="text-sm-right text-center">
            <v-btn v-if="data.status.name == 'blocked'" outlined elevation="0" width="180" height="48" color="success" @click="active">
              Activate
            </v-btn>
            <v-btn v-else outlined elevation="0" width="180" height="48" color="error" @click="disable">Deactivate</v-btn>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
    };
  },
  mounted() {
    this.$store.dispatch('getUser').finally(() => {
      this.load = false;
    });
  },
  methods: {
    getColorClass(block) {
      if (block == 'invited') {
        return 'yellow darken-2';
      } else if (block == 'active') {
        return 'success';
      } else if (block == 'blocked') {
        return 'error';
      }
    },
    async disable() {
      await this.$store.dispatch('getUserDeactiv');
    },
    async active() {
      await this.$store.dispatch('getUserActiv');
    },
  },
  computed: {
    data() {
      return this.$store.getters.user;
    },
  },
  destroyed() {
    this.$store.dispatch('setUser', { status: {} });
  },
};
</script>

<style lang="scss">
.profile {
  .status {
    line-height: 38px;
    padding: 0 16px;
    border-radius: 24px;
  }

  .auth {
    line-height: 26px;
    border-radius: 24px;
  }
}
</style>
